import {IUser} from '@/shared/models';
import {parseJwt} from '@/shared/utils/authUtils.ts';
import {
  customerServiceDepartmentId,
  supportDepartmentId
} from '@/shared/constants/departmentConstants.ts';

import {RootState} from '../index';

export const authSelector = (state: RootState) => state.authState.isAuth;
export const authTokenSelector = (state: RootState) => state.authState.accessToken;

export const userInfoSelector = (state: RootState) => state.authState.userInfo as IUser;

export const isSupportUserSelector = (state: RootState) => {
  try {
    const accessToken = parseJwt(state.authState.accessToken || '');

    if (accessToken?.sub) {
      const userResponse = JSON.parse(accessToken.sub);

      if (
        userResponse.user_departments.find(
          ({department_id}: {department_id?: number}) => department_id === supportDepartmentId
        )
      ) {
        return true;
      }
    }
  } catch (e) {
    return false;
  }
};

export const isCustomerServiceUserSelector = (state: RootState) => {
  try {
    const accessToken = parseJwt(state.authState.accessToken || '');

    if (accessToken?.sub) {
      const userResponse = JSON.parse(accessToken.sub);

      if (
        userResponse.user_departments.find(
          ({department_id}: {department_id?: number}) =>
            department_id === customerServiceDepartmentId
        )
      ) {
        return true;
      }
    }
  } catch (e) {
    return false;
  }
};
