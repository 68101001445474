import React, {FC, forwardRef} from 'react';
import {Backdrop, Box, Button, Grid, Modal, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';

import {FormComboBoxWithConnect} from '@/components/Form/components/FormComboBox';
import useLoading from '@/shared/hooks/useLoading';
import {useCompanyLogic} from '@/modals/create/hooks/useCompanyLogic';
import {ICompany} from '@/shared/models/companyModel';
import {useAppSelector} from '@/stores/hooks';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {useCreateChatMutation} from '@/stores/api/chats.ts';
import {IChatResponse} from '@/shared/models/chatModel.ts';
import {inviteLinkPattern} from '@/shared/constants/telegramConstants.ts';

import {FormValues, prepareRequestData} from './utils';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const CreateChat: FC<IProps> = forwardRef<HTMLInputElement, IProps>(({open, onClose}, _ref) => {
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const [submitCreateChat, {isLoading: isSubmitLoading, isError}] = useCreateChatMutation();

  useLoading(isSubmitLoading);

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm<FormValues>();

  const watchCompany = watch('company');

  /**
   * СПИСОК КЛИЕНТОВ
   */

  const {fetchCompanies, query, isCompaniesLoading} = useCompanyLogic({setValue});

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (data) {
      const requestData = prepareRequestData({...data});
      const result: {data: IChatResponse} | {error: unknown} = await submitCreateChat(requestData);

      if ('data' in result && result?.data) {
        onClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Создание чата"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} paddingBottom={8}>
            <label htmlFor="company">
              Клиент <span className="imp">*</span>
            </label>
            <FormComboBoxWithConnect
              id="company"
              name="company"
              required
              isLoading={isCompaniesLoading}
              control={control}
              placeholder="Выберите клиента"
              getData={fetchCompanies}
              query={query}
              renderOption={({className, ...props}: any, option: ICompany) => {
                const isImp = checkCompanyIndividual(
                  companyIndividualType,
                  option.companyTypeId,
                  option.name
                );
                return (
                  <li {...props} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              inputClassName={
                checkCompanyIndividual(
                  companyIndividualType,
                  watchCompany?.companyTypeId,
                  watchCompany?.name
                )
                  ? 'imp'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} paddingBottom={8}>
            <label htmlFor="telegram_invite_link">
              Пригласительная ссылка <span className="imp">*</span>
            </label>
            <Controller
              name="telegram_invite_link"
              control={control}
              defaultValue={undefined}
              rules={{
                required: 'Пригласительная ссылка обязательна',
                pattern: {
                  value: inviteLinkPattern,
                  message: 'Укажите ссылку в формате: https://t.me/+ABC123'
                }
              }}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  id="telegram_invite_link"
                  required
                  placeholder="Укажите пригласительную ссылку чата в телеграм"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="custom_title">Название чата</label>
            <Controller
              name="custom_title"
              control={control}
              defaultValue={undefined}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  id="custom_title"
                  placeholder="Оставьте поле пустым, чтобы заполнить автоматически"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </Grid>
          <Grid sx={{paddingTop: 15}} container justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={onClose}
                sx={{minWidth: 154, borderWidth: 2}}
              >
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitLoading}
                loadingIndicator="Создание…"
                sx={{minWidth: 154}}
              >
                Создать
              </LoadingButton>
            </Grid>
          </Grid>
          {isError && (
            <Grid item xs={12} textAlign={'center'} sx={{paddingTop: 15}}>
              <span className="error">Ошибка создания чата</span>
            </Grid>
          )}
        </form>
      </Box>
    </Modal>
  );
});

CreateChat.displayName = 'CreateChat';

export default CreateChat;
