import React from 'react';
import {createBrowserRouter, Navigate} from 'react-router-dom';

import {ProtectedPageLayout} from '@/layouts/ProtectedPageLayout';
import {AuthLayout} from '@/layouts/AuthLayout';
import {ErrorPage} from '@/scenes/ErrorPage';
// import {Home} from '@/scenes/Home';
// import {Tasks} from '@/scenes/Tasks';
import {TaskPage} from '@/scenes/TaskPage';
import {LoginPage} from '@/scenes/LoginPage';
import {SubtaskPage} from '@/scenes/SubtaskPage';
import {RoutePaths} from '@/shared/constants/route';
import {AllTasksPage} from '@/scenes/AllTasksPage';
import {Tasks} from '@/scenes/AllTasksPage/scenes/Tasks';
import {Subtasks} from '@/scenes/AllTasksPage/scenes/Subtasks';
import {SupportTasks} from '@/scenes/AllTasksPage/scenes/SupportTasks';
import {SupportTaskPage} from '@/scenes/SupportTaskPage';
import {ChatsPage} from '@/scenes/ChatsPage';
import {GuestLayout} from '@/layouts/GuestLayout';
import {FeedbackPage} from '@/scenes/FeedbackPage';
import {ForgotPasswordPage} from '@/scenes/ForgotPasswordPage';
import {AnalyticsPage} from '@/scenes/AnalyticsPage';
import {NpsPage} from '@/scenes/NpsPage';

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedPageLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Navigate to={RoutePaths.Main} replace />
      },
      {
        path: RoutePaths.Main,
        element: <AllTasksPage />,
        children: [
          {
            path: RoutePaths.Main,
            element: <Tasks />
          },
          {
            path: RoutePaths.MainSubtasks,
            element: <Subtasks />
          },
          {
            path: RoutePaths.MainTechSupport,
            element: <SupportTasks />
          }
        ]
      },
      {
        path: RoutePaths.TaskPage,
        element: <TaskPage />
      },
      {
        path: RoutePaths.SubtaskPage,
        element: <SubtaskPage />
      },
      {
        path: RoutePaths.SupportTaskPage,
        element: <SupportTaskPage />
      },
      {
        path: RoutePaths.Tasks,
        element: <Navigate to={RoutePaths.Main} replace />
      },
      {
        path: RoutePaths.ChatGroups,
        element: <ChatsPage />,
        children: [
          {
            path: RoutePaths.ChatGroupPage,
            element: <ChatsPage />
          },
          {
            path: RoutePaths.ChatPage,
            element: <ChatsPage />
          },
          {
            path: RoutePaths.ChatTopicPage,
            element: <ChatsPage />
          }
        ]
      },
      {
        path: RoutePaths.Analytics,
        element: <AnalyticsPage />
      },
      {
        path: RoutePaths.Nps,
        element: <NpsPage />
      }
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: RoutePaths.LoginPage,
        element: <LoginPage />
        // loader: redirectIfUser,
      },
      {
        path: RoutePaths.ForgotPassword,
        element: <ForgotPasswordPage />
      },
      {
        path: 'logout'
        // action: logoutUser,
      }
    ]
  },
  {
    element: <GuestLayout />,
    children: [
      {
        path: RoutePaths.Rate,
        element: <FeedbackPage />
        // loader: redirectIfUser,
      }
    ]
  }
]);
