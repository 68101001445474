import React, {FC, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router';
import {Backdrop, Button, LinearProgress, Modal, Stack, Tooltip, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TelegramIcon from '@mui/icons-material/Telegram';

import {RoutePaths} from '@/shared/constants/route.ts';
import {getParticipantsLabel} from '@/shared/utils/stringUtils';
import {ReactComponent as PlusCircleIcon} from '@/assets/plus-circle.svg';
import {ReactComponent as PlusIcon} from '@/assets/plus.svg';
import {ReactComponent as DisableInfoIcon} from '@/assets/info-circle-disable.svg';
import {ReactComponent as InfoIcon} from '@/assets/info-circle.svg';
import {ChatHeader, ChatCloseButton} from '@/shared/styles/chatStyles';
import {CreateTaskChat} from '@/modals/create/CreateTaskChat';
import {IChat, IChatMessage} from '@/shared/models/chatModel.ts';
import {AddTaskChatMessage} from '@/modals/add/AddTaskChatMessage';

interface IProps {
  chatData?: IChat;
  chatGroupId?: string;
  chatId?: string;
  isOpenChatInfo: boolean;
  setIsOpenChatInfo: (value: (prev: boolean) => boolean) => void;
  isLoadingPage: boolean;
  setIsSelectingChatMessages: (value: boolean) => void;
  selectedChatMessages: IChatMessage[];
  onSubmit: () => void;
}

const ChatMessageHeader: FC<IProps> = ({
  chatData,
  chatGroupId,
  chatId,
  isOpenChatInfo,
  setIsOpenChatInfo,
  isLoadingPage,
  setIsSelectingChatMessages,
  selectedChatMessages,
  onSubmit
}) => {
  const navigate = useNavigate();
  const [isShowTaskButtons, setIsShowTaskButtons] = useState<boolean>(false);
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const handleOpenCreateTask = () => setOpenCreateTask(true);
  const handleCloseCreateTask = () => setOpenCreateTask(false);
  const handleSubmitCreateTask = () => {
    setOpenCreateTask(false);
    setIsShowTaskButtons(false);
    setIsSelectingChatMessages(false);
    onSubmit();
  };

  const [openAddTaskComment, setOpenAddTaskComment] = useState(false);
  const handleOpenAddTaskComment = () => setOpenAddTaskComment(true);
  const handleCloseAddTaskComment = () => setOpenAddTaskComment(false);
  const handleSubmitAddTaskComment = () => {
    setOpenAddTaskComment(false);
    setIsShowTaskButtons(false);
    setIsSelectingChatMessages(false);
    onSubmit();
  };

  const handleClickClose = () => {
    const path = generatePath(RoutePaths.ChatGroupPage, {
      chatGroupId: `${chatGroupId}`
    });
    navigate(path);
  };

  const handleClickSelectingMessages = () => {
    setIsShowTaskButtons(!isShowTaskButtons);
    setIsSelectingChatMessages(!isShowTaskButtons);
  };

  useEffect(() => {
    setIsShowTaskButtons(false);
    setIsSelectingChatMessages(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return (
    <>
      <ChatHeader>
        <Stack direction="column">
          <Stack direction="row">
            {chatData?.isTelegram && (
              <Tooltip title={'Telegram'}>
                <TelegramIcon sx={{color: 'white', width: '20px', marginRight: '3px'}} />
              </Tooltip>
            )}
            {chatData?.title && (
              <Typography variant="h6" color={'white'} sx={{fontSize: '14px'}}>
                {chatData?.telegramId && !chatData?.telegramTopicId && chatData?.customTitle
                  ? chatData?.customTitle
                  : chatData?.title || 'Общий чат'}
              </Typography>
            )}
          </Stack>
          <Typography
            variant="subtitle2"
            color={'var(--nav-color-inactive)'}
            sx={{fontSize: '12px'}}
          >
            {getParticipantsLabel(chatData?.totalParticipantsCount || 0)}
          </Typography>
        </Stack>
        <div>
          {!isShowTaskButtons && (
            <Button
              variant="contained"
              color="secondary"
              style={{marginRight: '10px'}}
              onClick={handleClickSelectingMessages}
            >
              <Typography variant="body1" sx={{fontSize: '12px'}}>
                Выделить сообщения
              </Typography>
            </Button>
          )}
          {isShowTaskButtons && (
            <>
              <Button
                variant="contained"
                color="secondary"
                style={{marginRight: '10px'}}
                onClick={handleOpenAddTaskComment}
                disabled={!selectedChatMessages?.length}
              >
                <PlusIcon color="#fff" width={20} />
                <Typography variant="body1" sx={{color: 'white', fontSize: '12px'}}>
                  Добавить к задаче
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{marginRight: '10px', alignItems: 'center'}}
                onClick={handleOpenCreateTask}
                disabled={!selectedChatMessages?.length}
              >
                <PlusCircleIcon width={20} />
                <Typography
                  variant="body1"
                  sx={{color: 'white', paddingLeft: '6px', fontSize: '12px'}}
                >
                  Создать задачу
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{marginRight: '10px'}}
                onClick={handleClickSelectingMessages}
              >
                <Typography variant="body1" sx={{fontSize: '12px'}}>
                  Отменить выделение
                </Typography>
              </Button>
            </>
          )}
          <Tooltip title={isOpenChatInfo ? 'Скрыть информацию' : 'Показать информацию'}>
            <ChatCloseButton onClick={() => setIsOpenChatInfo(prev => !prev)}>
              {isOpenChatInfo ? <InfoIcon color={'white'} /> : <DisableInfoIcon />}
            </ChatCloseButton>
          </Tooltip>
          <Tooltip title="Закрыть">
            <ChatCloseButton onClick={handleClickClose}>
              <CloseIcon />
            </ChatCloseButton>
          </Tooltip>
        </div>
      </ChatHeader>
      {isLoadingPage && (
        <div className="scene-linear-loader">{isLoadingPage ? <LinearProgress /> : ' '}</div>
      )}
      <Modal
        open={openCreateTask}
        onClose={handleCloseCreateTask}
        aria-labelledby="Окно создания задачи"
        closeAfterTransition
        disablePortal
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <CreateTaskChat
          onClose={handleCloseCreateTask}
          onSubmit={handleSubmitCreateTask}
          chatData={chatData}
          selectedChatMessages={selectedChatMessages}
          isOpen={openCreateTask}
        />
      </Modal>
      <Modal
        open={openAddTaskComment}
        onClose={handleCloseAddTaskComment}
        aria-labelledby="Окно добавления комментария в задачу"
        closeAfterTransition
        disablePortal
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <AddTaskChatMessage
          onClose={handleCloseAddTaskComment}
          onSubmit={handleSubmitAddTaskComment}
          chatData={chatData}
          selectedChatMessages={selectedChatMessages}
        />
      </Modal>
    </>
  );
};

export default ChatMessageHeader;
