import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Stack, Typography} from '@mui/material';

import ChatListSection from '@/scenes/ChatsPage/components/ChatListSection/ChatListSection';
import ChatMessageSection from '@/scenes/ChatsPage/components/ChatMessageSection/ChatMessageSection';
import ChatInfoSection from '@/scenes/ChatsPage/components/ChatInfoSection/ChatInfoSection';
import {CreateButton} from '@/scenes/ChatsPage/components/CreateButton';
import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {}

const ChatsPage: FC<IProps> = () => {
  const {chatGroupId, chatId, chatTopicId} = useParams<{
    chatGroupId?: string;
    chatId?: string;
    chatTopicId?: string;
  }>();
  const currentUser = useAppSelector(userInfoSelector);
  const isHead = currentUser?.isHead;
  const isHeadUnit = currentUser?.isHeadUnit;
  const isCreatingChat = currentUser?.isCreatingChat;

  const [isFetchingMessages, setIsFetchingMessages] = useState<boolean | undefined>(undefined);
  const [isOpenChatInfo, setIsOpenChatInfo] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenChatInfo(false);
  }, [chatId, chatTopicId]);

  return (
    <div className="scene" style={{display: 'flex', height: '100vh'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom={true} sx={{marginBottom: '2rem'}}>
          Чат
        </Typography>
        <div className="header-section">
          {(isHead || isHeadUnit || isCreatingChat) && <CreateButton />}
        </div>
      </header>
      <Stack direction="row" spacing={10} sx={{height: '92%'}}>
        <ChatListSection
          chatGroupId={chatGroupId}
          chatId={chatId}
          chatTopicId={chatTopicId}
          isFetchingMessages={isFetchingMessages}
        />
        <Stack
          direction="row"
          sx={{
            width: isOpenChatInfo ? '50%' : '75%',
            transition: 'width 0.3s'
          }}
        >
          {chatId && (
            <ChatMessageSection
              chatGroupId={chatGroupId}
              chatId={chatTopicId ? chatTopicId : chatId}
              setIsFetchingMessages={setIsFetchingMessages}
              isOpenChatInfo={isOpenChatInfo}
              setIsOpenChatInfo={setIsOpenChatInfo}
            />
          )}
        </Stack>
        {isOpenChatInfo && <ChatInfoSection chatId={chatTopicId ? chatTopicId : chatId} />}
      </Stack>
    </div>
  );
};

export default ChatsPage;
