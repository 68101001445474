import React, {FC, useCallback, useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import EditIcon from '@mui/icons-material/Edit';
import {CheckCircle} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Link} from 'react-router-dom';

import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IChatCustomer, IChatUser} from '@/shared/models/chatModel.ts';
import {ChatInfoButton, DividerHr, EllipsisTypography} from '@/shared/styles/chatStyles';
import {EditCustomerName} from '@/modals/edit/EditCustomerName';
import {IUser} from '@/shared/models';
import {useUpdateChatMutation} from '@/stores/api/chats.ts';
import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {
  chatId?: string;
  isLoadingChat?: boolean;
  isFetchingChat?: boolean;
  refetchChat: () => void;
  chatUsers?: IChatUser[];
  chatCustomers?: IChatCustomer[];
}

const ChatInfoParticipants: FC<IProps> = ({
  chatId,
  isLoadingChat,
  isFetchingChat,
  refetchChat,
  chatUsers,
  chatCustomers
}) => {
  const currentUser = useAppSelector(userInfoSelector);
  const [submitUpdateChat, {isLoading: isUpdateLoading}] = useUpdateChatMutation();

  const [isOpenParticipants, setIsOpenParticipants] = useState<boolean>(false);
  const [isEditCustomerOpen, setIsEditCustomerOpen] = useState<boolean>(false);
  const [isSelectingCustomers, setIsSelectingCustomers] = useState<boolean>(false);
  const [selectedCustomers, setSelectedCustomers] = useState<IUser[]>([]);
  const [isCheckedCustomerIds, setIsCheckedCustomerIds] = useState<{[key: string]: boolean}>({});
  const [selectedCustomer, setSelectedCustomer] = useState<IUser | undefined>(undefined);

  const isShowEditCustomerButton =
    (currentUser?.isHead || currentUser?.isHeadUnit || currentUser?.isCreatingChat) &&
    chatCustomers?.some(chatCustomer => !chatCustomer.customer.isTelegram);

  const handleEditCustomerOpen = (customer: IUser) => {
    setSelectedCustomer(customer);
    setIsEditCustomerOpen(true);
  };

  const handleDeleteCustomers = useCallback(async () => {
    if (!chatId) {
      console.warn('chatId не найден');
      return;
    }

    if (!selectedCustomers) {
      return;
    }

    const customer_ids =
      chatCustomers
        ?.filter(
          customer => !selectedCustomers.some(selected => selected.id === customer.customerId)
        )
        .map(customer => customer.customerId) || [];

    if (!customer_ids) {
      return;
    }

    const params = {
      chatId: String(chatId),
      customer_ids: customer_ids
    };

    const isEmpty = Object.keys(params).filter(key => key !== 'chatId').length === 0;
    if (!isEmpty) {
      await submitUpdateChat(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, selectedCustomers]);

  const handleSelectCustomer = (customer: IUser) => {
    if (setSelectedCustomers) {
      setSelectedCustomers(prevSelected => {
        let updatedSelected;

        if (isCheckedCustomerIds[customer.id]) {
          updatedSelected = prevSelected.filter((m: {id: number}) => m.id !== customer.id);
        } else {
          updatedSelected = [...prevSelected, customer];
        }

        return updatedSelected.sort((a: {id: number}, b: {id: number}) => a.id - b.id);
      });
    }

    setIsCheckedCustomerIds(prevState => ({
      ...prevState,
      [customer.id]: !prevState[customer.id]
    }));
  };

  useEffect(() => {
    setIsCheckedCustomerIds(() => {
      return {};
    });
    if (setSelectedCustomers) {
      setSelectedCustomers(() => {
        return [];
      });
    }
  }, [isSelectingCustomers, setSelectedCustomers]);

  useEffect(() => {
    setIsOpenParticipants(!!chatUsers || !!chatCustomers);
  }, [chatUsers, chatCustomers]);

  return (
    <Stack padding={6}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => setIsOpenParticipants(prevState => !prevState && !isFetchingChat)}
      >
        <Typography variant="h2">Участники чата</Typography>
      </Button>
      {isLoadingChat && (!chatUsers || !chatCustomers) && (
        <Stack padding={6} alignItems={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <Collapse in={isOpenParticipants} timeout="auto" unmountOnExit>
        {chatUsers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Сотрудники
              </Typography>
              {chatUsers.map(chatUser => (
                <React.Fragment key={chatUser.user.id}>
                  <DividerHr />
                  <Stack direction={'column'} paddingX={8}>
                    <Link
                      to={`https://peb.bitrix24.ru/company/personal/user/${chatUser.user.bitrixId}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      <EllipsisTypography variant="body1">
                        {formatUserName(chatUser.user)}
                      </EllipsisTypography>
                    </Link>
                    <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                      {chatUser.user.position}
                    </EllipsisTypography>
                  </Stack>
                </React.Fragment>
              ))}
            </Stack>
          </>
        )}
        {chatCustomers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Клиенты
              </Typography>
              {chatCustomers.map(chatCustomer => (
                <React.Fragment key={chatCustomer.customer.id}>
                  <DividerHr />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{width: '100%'}}
                  >
                    <Stack direction="row" alignItems="center">
                      {!chatCustomer.customer.isTelegram && isSelectingCustomers && (
                        <Stack direction="row" alignItems={'center'}>
                          <Tooltip
                            title={
                              !(isCheckedCustomerIds[chatCustomer.customer.id] || false)
                                ? 'Выделить'
                                : 'Не выделять'
                            }
                          >
                            <Checkbox
                              color={'secondary'}
                              checkedIcon={<CheckCircle />}
                              checked={isCheckedCustomerIds[chatCustomer.customer.id] || false}
                              onChange={() => handleSelectCustomer(chatCustomer.customer)}
                              inputProps={{'aria-label': 'Выделить'}}
                              sx={{padding: 0, marginRight: 0, flexShrink: 0}}
                            />
                          </Tooltip>
                        </Stack>
                      )}
                      {chatCustomer.customer?.isTelegram && (
                        <Tooltip title={'Telegram'}>
                          <TelegramIcon sx={{color: 'black', width: '20px'}} />
                        </Tooltip>
                      )}
                      <Stack
                        direction="column"
                        paddingX={chatCustomer.customer?.isTelegram ? 4 : 8}
                      >
                        <EllipsisTypography variant="body1">
                          {formatUserName(chatCustomer.customer)}
                        </EllipsisTypography>
                        <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                          {chatCustomer.customer.email}
                        </EllipsisTypography>
                      </Stack>
                    </Stack>
                    {chatCustomer.customer?.isTelegram && (
                      <Tooltip title={'Редактировать'}>
                        <EditIcon
                          sx={{color: 'black', width: '20px', cursor: 'pointer'}}
                          onClick={() => handleEditCustomerOpen(chatCustomer.customer)}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </React.Fragment>
              ))}
            </Stack>
            {!isSelectingCustomers && isShowEditCustomerButton && (
              <Stack paddingTop={18} paddingLeft={48} paddingRight={48}>
                <ChatInfoButton
                  variant={'contained'}
                  onClick={() => setIsSelectingCustomers(prevState => !prevState)}
                >
                  <Typography variant="body1">Редактировать</Typography>
                </ChatInfoButton>
              </Stack>
            )}
            {isSelectingCustomers && (
              <Stack direction="row" paddingTop={18} spacing={10}>
                <ChatInfoButton
                  fullWidth
                  variant={'contained'}
                  onClick={() => setIsSelectingCustomers(prevState => !prevState)}
                >
                  <Typography variant="body1">Отмена</Typography>
                </ChatInfoButton>
                <LoadingButton
                  fullWidth
                  variant={'contained'}
                  color={'error'}
                  loading={isUpdateLoading}
                  onClick={() => {
                    handleDeleteCustomers().then(() =>
                      setIsSelectingCustomers(prevState => !prevState)
                    );
                  }}
                >
                  <Typography variant="body1">Удалить</Typography>
                </LoadingButton>
              </Stack>
            )}
          </>
        )}
      </Collapse>

      <EditCustomerName
        open={isEditCustomerOpen}
        customer={selectedCustomer}
        setIsEditCustomerOpen={setIsEditCustomerOpen}
        refetchChat={refetchChat}
      />
    </Stack>
  );
};

export default ChatInfoParticipants;
