import React, {FC, useState} from 'react';
import {Typography, Stack} from '@mui/material';

import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {}

const AnalyticsPage: FC<IProps> = () => {
  const currentUser = useAppSelector(userInfoSelector);
  const isHead = currentUser.isHead;
  const isHeadUnit = currentUser.isHeadUnit;

  const REDASH_URL = import.meta.env.REDASH_URL || 'https://redash.infra.peb.guru/embed';
  const REDASH_ANALYTIC_TASK_QUERY_KEY =
    import.meta.env.REDASH_ANALYTIC_TASK_QUERY_KEY || 'RAqqWbsrJfi094maTOwYRge39UYYL9POfhKR6PXJ';

  const REDASH_ANALYTIC_RATE_QUERY_KEY =
    import.meta.env.REDASH_ANALYTIC_RATE_QUERY_KEY || 'C203c480Gs4HZtaGgmzOjDOmGRSQPBeZOOhNvm6T';

  const analyticTaskQuery = '108';
  const analyticTaskVisualization = '120';

  const evaluationTaskQuery = '109';
  const evaluationTaskVisualization = '121';

  const [params] = useState({
    period: 'd_last_12_months',
    departmentIds: [
      '1',
      '20',
      '14',
      '18',
      '3',
      '9',
      '6',
      '10',
      '15',
      '19',
      '4',
      '11',
      '7',
      '16',
      '2',
      '12',
      '17',
      '5',
      '13',
      '8',
      '28',
      '29',
      '31',
      '30',
      '33',
      '32',
      '36',
      '35',
      '34',
      '38',
      '37'
    ]
  });

  return (
    <div className="scene" style={{display: 'flex'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom sx={{marginBottom: '2rem'}}>
          Аналитика
        </Typography>
      </header>
      <Stack direction="column" spacing={4} sx={{flex: 1}}>
        <iframe
          title={'Аналитика задач'}
          src={`${REDASH_URL}/query/${analyticTaskQuery}/visualization/${analyticTaskVisualization}?hide_link&api_key=${REDASH_ANALYTIC_TASK_QUERY_KEY}&p_period=${
            params.period
          }&p_department_ids=${encodeURIComponent(JSON.stringify(params.departmentIds))}`}
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        {(isHead || isHeadUnit) && (
          <iframe
            title={'Оценки задач'}
            src={`${REDASH_URL}/query/${evaluationTaskQuery}/visualization/${evaluationTaskVisualization}?hide_link&api_key=${REDASH_ANALYTIC_RATE_QUERY_KEY}&p_period=${
              params.period
            }&p_department_ids=${encodeURIComponent(JSON.stringify(params.departmentIds))}`}
            style={{minWidth: '100%', minHeight: '100%'}}
            scrolling="no"
          ></iframe>
        )}
      </Stack>
    </div>
  );
};

export default AnalyticsPage;
