const fileStorageUrl = import.meta.env.APP_FILE_STORAGE_URL || '';
const fileStorageContainer = import.meta.env.APP_FILE_STORAGE_CONTAINER || '';
const fileStorageBucket = import.meta.env.APP_FILE_STORAGE_BUCKET || '';
const fileStorageUser = import.meta.env.APP_FILE_STORAGE_USER || '';
const fileStoragePassword = import.meta.env.APP_FILE_STORAGE_PASSWORD || '';

export default {
  fileStorageUrl,
  fileStorage: {
    url: fileStorageUrl,
    user: fileStorageUser,
    key: fileStoragePassword,
    container: fileStorageContainer,
    bucket: fileStorageBucket
  },
  s3: {
    credentials: {
      accessKeyId: fileStorageUser,
      secretAccessKey: fileStoragePassword
    },
    endpoint: fileStorageUrl + 'v1/'
  }
};
