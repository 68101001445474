import {useCallback, useEffect} from 'react';
import useWebSocket from 'react-use-websocket';
import {Draft} from '@reduxjs/toolkit';

import {useAppDispatch, useAppSelector} from '@/stores/hooks.ts';
import {authTokenSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import {ESocketEvent} from '@/shared/models/socketTypes.ts';
import {formatKeys} from '@/shared/utils/responseUtils.ts';
import usePrevious from '@/shared/hooks/usePrevious.ts';
import {withSocket} from '@/shared/constants/appConstants.ts';
import {apiWithStatistic} from '@/stores/api/statistics.ts';
import {IStatistic, IUnreadChatMessagesCount} from '@/shared/models/statisticModel.ts';
import {apiWithChat} from '@/stores/api/chats.ts';

const SOCKET_URL = import.meta.env.APP_SOCKET_URL || '';

export const useStatisticChatsStreaming = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authTokenSelector);
  const WS_URL = SOCKET_URL + '/statistics/chats';
  const prevToken = usePrevious(accessToken);

  const {lastMessage} = useWebSocket(
    WS_URL,
    {
      onOpen: () => console.debug('opened'),
      onClose: () => console.debug('closed'),
      share: false,
      shouldReconnect: () => {
        if (prevToken && prevToken !== accessToken) {
          console.log('reconnect', {prevToken, accessToken});
          return true;
        }
        return true;
      },
      queryParams: {
        token: accessToken
      }
    },
    withSocket
  );

  const handleUnreadMessagesCount = useCallback(
    (message: IUnreadChatMessagesCount) => {
      if (message) {
        const patch = formatKeys<IUnreadChatMessagesCount, NonNullable<IStatistic['chats']>>(
          message
        );

        dispatch(
          apiWithStatistic.util.updateQueryData(
            'getStatisticChats',
            undefined,
            (draft: Draft<IStatistic>) => {
              if (patch && patch.unreadChatMessagesCount !== undefined && draft.chats) {
                draft.chats.unreadChatMessagesCount = patch.unreadChatMessagesCount;
              }
            }
          )
        );

        dispatch(apiWithChat.util.invalidateTags([{type: 'CHAT_GROUP_LIST', id: 'PARTIAL-LIST'}]));
        dispatch(apiWithChat.util.invalidateTags([{type: 'CHAT_LIST', id: 'PARTIAL-LIST'}]));
        dispatch(apiWithChat.util.invalidateTags([{type: 'CHAT_TOPIC_LIST', id: 'PARTIAL-LIST'}]));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const commentData = JSON.parse(lastMessage.data);
      console.debug('Got a new message: ', commentData);

      switch (commentData.type) {
        case ESocketEvent.ChangeUnreadChatMessagesCount:
          handleUnreadMessagesCount(commentData.data);
          break;
      }
    }
  }, [handleUnreadMessagesCount, lastMessage]);

  return null;
};
