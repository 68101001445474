import React, {FC, useCallback, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Collapse, Stack, Typography} from '@mui/material';

import {Input} from '@/components/UIKit';
import {IChat} from '@/shared/models/chatModel.ts';
import {ChatInfoLoadingButton} from '@/shared/styles/chatStyles';
import {useUpdateChatMutation} from '@/stores/api/chats.ts';
import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {
  chatData?: IChat;
}

const ChatInfoTitle: FC<IProps> = ({chatData}) => {
  const currentUser = useAppSelector(userInfoSelector);
  const [submitUpdateChat, {isLoading: isUpdateLoading}] = useUpdateChatMutation();

  const [isOpenTitle, setIsOpenTitle] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string | undefined>(undefined);

  const isDisabledInput = !(
    currentUser?.isHead ||
    currentUser?.isHeadUnit ||
    currentUser?.isCreatingChat
  );

  const isDisabledButton =
    isDisabledInput || chatData?.customTitle === titleValue || titleValue === '';

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitleValue(value);
  };

  useEffect(() => {
    if (chatData?.customTitle) {
      setTitleValue(chatData.customTitle);
    } else {
      setTitleValue('');
    }
  }, [chatData?.customTitle]);

  const handleUpdate = useCallback(async () => {
    if (!chatData?.id) {
      console.warn('chatId не найден');
      return;
    }

    const params = {
      chatId: String(chatData.id),
      custom_title: titleValue
    };

    const isEmpty = Object.keys(params).filter(key => key !== 'chatId').length === 0;
    if (!isEmpty) {
      await submitUpdateChat(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, titleValue]);

  return (
    <>
      <Stack padding={6}>
        <Button
          variant={'contained'}
          color={'secondary'}
          onClick={() => setIsOpenTitle(prevState => !prevState)}
        >
          <Typography variant="h2">Название</Typography>
        </Button>
        {!chatData && (
          <Stack padding={6} alignItems={'center'}>
            <CircularProgress color="inherit" />
          </Stack>
        )}
        <Collapse in={isOpenTitle} timeout="auto" unmountOnExit>
          <Stack
            direction="row"
            paddingTop={10}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box flexBasis="80%">
              <Input
                fullWidth
                autoComplete="off"
                value={titleValue}
                onChange={handleChangeTitle}
                label="Название чата"
                disabled={isDisabledInput}
                FormHelperTextProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minHeight: '20px',
                    marginTop: '4px'
                  }
                }}
              />
            </Box>
            <Box flexBasis="30%" marginLeft="5%">
              <ChatInfoLoadingButton
                variant={'contained'}
                color={'success'}
                fullWidth
                onClick={handleUpdate}
                loading={isUpdateLoading}
                loadingPosition="center"
                disabled={isDisabledButton}
              >
                <Typography variant="body1" sx={{fontSize: '12px'}}>
                  Обновить
                </Typography>
              </ChatInfoLoadingButton>
            </Box>
          </Stack>
        </Collapse>
      </Stack>
    </>
  );
};

export default ChatInfoTitle;
