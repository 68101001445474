import pickBy from 'lodash/pickBy';

import {IOption} from '@/shared/models/tasksFilterModel';
import {ICompany} from '@/shared/models/companyModel';
import {IFile} from '@/shared/models/fileModel';
import {IDepartment} from '@/shared/models/dictionaryModel';

export type FormValues = {
  company: ICompany;
  taskType?: IOption;
  employeeList: IOption[];
  department: IDepartment;
  mailTemplate: IOption; // подставлять шаблон в поле content
  senders: string[];
  title: string;
  content: string;
  attachments?: IFile[];
  delayAt?: string | null | undefined;
  delayReason?: string;
};

export const prepareRequestData = (data: FormValues) => {
  const {
    company,
    department,
    taskType,
    employeeList,
    senders = ['isSendMail'],
    title,
    content,
    attachments,
    delayReason,
    delayAt = null
  } = data;

  if (!taskType?.id) {
    throw new Error('Type id не задан');
  }
  let taskSourceId = 0;

  let dontClose = false;

  let taskStatusId = 1;

  const isSendMail = senders.includes('isSendMail');
  const isSendMobile = senders.includes('isSendMobile');
  const isSendBitrix = senders.includes('isSendBitrix');
  const shouldDelay = senders.includes('delayTo');

  if (isSendMail && isSendMobile) {
    taskSourceId = 3;
    dontClose = senders.includes('dontClose');
  } else if (isSendMail) {
    taskSourceId = 1;
    dontClose = senders.includes('dontClose');
  } else if (isSendMobile) {
    taskSourceId = 2;
    dontClose = senders.includes('dontClose');
  } else if (isSendBitrix) {
    taskSourceId = 5;
    dontClose = senders.includes('dontClose');
  }

  if (shouldDelay) {
    taskStatusId = 6;
  }

  const copies = employeeList.length > 1 ? employeeList.slice(1).map(item => item.id) : [];

  // 1. При создании задачи и подзадачи необходимо слать unit_id выбранного отдела
  // 2. Если выбран кросс юнитовый отдел при создании задачи, нужно слать в запросе unit_id компании
  const unitId = department?.isCrossUnit ? company.unitId : department.unitDepartment?.unitId;

  const preparedData = {
    title,
    company_id: company.id,
    employee_id: employeeList[0].id,
    to_copy_employees_ids: copies,
    task_type_id: taskType.id,
    task_source_id: taskSourceId,
    task_status_id: taskStatusId,
    dont_close: dontClose,
    department_id: department.id,
    unit_id: unitId,
    delay_at: delayAt,
    task_comment: {
      content,
      attachments
    },
    delay_task_comment: {
      content: delayReason
    }
  };

  return pickBy(preparedData);
};
