import React, {FC, useState} from 'react';
import {Stack, Typography} from '@mui/material';

interface IProps {}

const NpsPage: FC<IProps> = () => {
  const REDASH_URL = import.meta.env.REDASH_URL || 'https://redash.infra.peb.guru/embed';
  const REDASH_NPS_COMPANY_QUERY_KEY =
    import.meta.env.REDASH_NPS_COMPANY_QUERY_KEY || 'HVlAXVJKIMbyX9fzXWbagsftCQCQECcySayT8apX';

  const REDASH_NPS_DATE_QUERY_KEY =
    import.meta.env.REDASH_NPS_DATE_QUERY_KEY || 'Lz0pM4xtJJZKcddo8MpieJtHXDFdP7NqbWGyxJar';

  const REDASH_NPS_TASK_COMPANY_QUERY_KEY = 'n3OKFEqs09sGhJGBBLoUSokmhM0C7mDSxGjNjcmr';

  const REDASH_NPS_TASK_DATE_QUERY_KEY = 'kmvlT5JMTS8g6TVoW6uVEy4dFQ02e9Hgwq2ptqoV';

  const npsCompanyQuery = '162';
  const npsCompanyVisualization = '174';

  const npsDateQuery = '164';
  const npsDateVisualization = '176';

  const npsTaskCompanyQuery = '166';
  const npsTaskCompanyVisualization = '178';

  const npsTaskDateQuery = '165';
  const npsTaskDateVisualization = '177';

  const [params] = useState({
    lastSurveyDate: '2024-11-05',
    companyId: '12'
  });

  return (
    <div className="scene" style={{display: 'flex'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom sx={{marginBottom: '2rem'}}>
          NPS
        </Typography>
      </header>
      <Stack direction="column" spacing={4} sx={{flex: 1}}>
        <iframe
          title={'Статистика NPS по клиенту'}
          src={`${REDASH_URL}/query/${npsCompanyQuery}/visualization/${npsCompanyVisualization}?hide_link&api_key=${REDASH_NPS_COMPANY_QUERY_KEY}&p_company_id=${encodeURIComponent(
            params.companyId
          )}`}
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика NPS по дате опроса'}
          src={`${REDASH_URL}/query/${npsDateQuery}/visualization/${npsDateVisualization}?hide_link&api_key=${REDASH_NPS_DATE_QUERY_KEY}&p_last_survey_date=${params.lastSurveyDate}`}
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика задач NPS по клиенту'}
          src="https://redash.infra.peb.guru/embed/query/166/visualization/178?hide_link&api_key=n3OKFEqs09sGhJGBBLoUSokmhM0C7mDSxGjNjcmr&p_company_id=12"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика задач NPS по дате опроса'}
          src="https://redash.infra.peb.guru/embed/query/165/visualization/177?hide_link&api_key=kmvlT5JMTS8g6TVoW6uVEy4dFQ02e9Hgwq2ptqoV&p_last_survey_date=2024-11-05"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
      </Stack>
    </div>
  );
};

export default NpsPage;
